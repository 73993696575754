import React from 'react';
import Image from 'gatsby-image';
import styled from 'styled-components';
import BigTitle from '../components/shared/text/BigTitle';
import Section from '../components/shared/wraps/Section/Section';
import SEO from '../components/seo';
import { Wrapper } from 'src/components/Sites/ServicesPages/shared/ServicesPages';

import {
  RotatingLetter,
  NoWrap,
} from 'src/components/shared/Animations/Animations';
import ReactHtmlParser from 'react-html-parser';
import AnchorLink from "../components/shared/navigations/AnchorLink/AnchorLink";
import {graphql} from "gatsby";
import  LayoutCV  from "../layouts/LayoutCV";
import {useI18next} from "gatsby-plugin-react-i18next";
import useLng from "../hooks/useLng";

const StyledImage = styled(Image)`
  margin: 0;
  max-width: 100% !important;
`;

const StyledBigTitle = styled(BigTitle)`
  margin-bottom: 3.5rem;
  text-align: center;
  width: 100%;
  font-size: clamp(6rem, 8vw, 12rem);
  line-height: 1;
`;

const StyledBanner = styled.div`
  max-height: 22rem;
  margin-bottom: clamp(6rem, 8vw, 10rem);
  background-color: ${({ theme }) => theme.colors.black2};

  @media screen and (max-width: 991px) {
    max-height: unset;
  }
`;

const StyledText = styled.p`
  text-align: center;
  font-size: 2.5rem;
  color: white;
  padding: 4rem;
`

const ProjectFeatures = styled.ol`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: flex-end;
  height: 100%;
  counter-reset: features-counter;
  list-style: none;
  padding: 6rem;

  @media screen and (max-width: 991px) {
    align-items: flex-start;
    flex-direction: column;
    padding: 3rem;
  }
`;

const SingleFeature = styled.li`
  flex-basis: 33.333%;
  font-size: 3rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  counter-increment: features-counter;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 1;

  &::before {
    content: counter(features-counter) '.';
    font-size: 8rem;
    color: ${({ theme }) => theme.colors.main};
    margin-right: 2rem;
    line-height: 0.7;
  }

  @media screen and (max-width: 991px) {
    &:not(:last-child) {
      margin-bottom: 1.4rem;
    }

    &::before {
      display: flex;
      justify-content: flex-end;
      width: 5rem;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 2.4rem;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &::before {
      font-size: 6rem;
    }
  }
`;

const StyledSection = styled(Section)`
  padding-left: 0;
  padding-right: 0;
`;

const splitText = text => {
  const splittedText = text.split(' ');
  const lastWord = splittedText.slice(-1)[0];

  return [
    splittedText.slice(0, -1).join(' ') + ' ',
    lastWord.slice(0, -1),
    lastWord.slice(-1),
  ];
};

const WorkCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  padding-bottom: 5rem;
  
  @media(max-width: 991px){
    grid-template-columns: 1fr;
  }
`

const WorkCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayText};
  padding: 2.6rem;
  max-width: 950px;
  margin: 0 auto;
  border-radius: 0;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  .buttonBox{
    margin-top: 3rem;
    display: flex; 
    justify-content: center;
  }
`
const WorkImg = styled.img`
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
`

const Name = styled.p`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.black2};
  margin-bottom: 0.8rem;
  //margin-top: 0.5rem;
`
const Top = styled.div`
  display: flex;
  
  .SkillsBox{
    display: flex;
    flex-wrap: wrap;
  }
  .texts{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
const StylishButton = styled.button`
  background: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.6rem;
  border: none;
  padding:  0.9rem 1.2rem 0.9rem 1.2rem;
  position: relative;
  letter-spacing: 0.1rem;
  //line-height: 1;
  cursor: pointer;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  transition: all linear 0.3s;
  margin-bottom: 1.2rem;
  display: block;
  &:hover{
    color: ${({ theme }) => theme.colors.main};
    background: ${({ theme }) => theme.colors.black3};  }

`;
const Skill = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
  margin-top: 10px;
  border: 2px solid ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 1rem;
  font-size: 1.3rem;
  background:  ${({ theme }) => theme.colors.white};
`
const Content = styled.div`
 margin-top: 3rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 4rem;
  *{
   margin-bottom: 1rem; 
    color: ${({ theme }) => theme.colors.grayText};
  }
  ul, ol{
    padding-left: 2rem;
    margin-bottom: 1rem;
    & li::marker{
      content: '■  ';
      font-weight: bold;
      color: ${({ theme }) => theme.colors.main};

    }
  }
  p{
    margin-bottom: 1rem;
  }
  h1, h2, h3, h4, h5, h6{
    color: ${({ theme }) => theme.colors.black4};
    font-size: 18px;
  }
`
const NoOfferText = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  text-align: center;
  font-size: 2rem;
  font-style: italic;
  padding: 3rem 4rem 6rem 4rem;
  
`

const Project = ({ data, location: { pathname: path } }) => {
  const  {directusOfertyPracy} = data
  const {language} = useI18next()
  const {t} = useLng()
  return (
    <LayoutCV path={path} id={'oferty-pracy'}>
      <SEO title={`Praca - ${directusOfertyPracy.name}`} />
      <StyledSection nonGrid site nonFlex first>
        <Wrapper nonGrid>
          <StyledBigTitle>
            {language === "pl" ? <NoWrap>
              Prac
              <RotatingLetter delay={0.4}>a</RotatingLetter>
            </NoWrap> : <NoWrap>
              Wor
              <RotatingLetter delay={0.4}>k</RotatingLetter>
            </NoWrap>}
          </StyledBigTitle>
        </Wrapper>
          <StyledBanner>
            <StyledText>
              {t("workText")}
            </StyledText>
          </StyledBanner>
        <Wrapper nonGrid>
                <WorkCardGrid>
            {!!directusOfertyPracy &&
                      <WorkCard>
                        <Top>
                          <WorkImg src={"/images/offer.png"} />
                          <div className="texts">
                            <Name>{directusOfertyPracy.name}</Name>
                            <div className="SkillsBox">
                              {directusOfertyPracy.skills.map(skill => (
                              <Skill>{skill.skill_name}</Skill>
                              ))}
                            </div>
                          </div>
                        </Top>

                        <Content>
                          {ReactHtmlParser(language === "pl" ? directusOfertyPracy.content : directusOfertyPracy.content_en ?? "")}
                        </Content>
                        <div className="buttonBox">
                          <AnchorLink bottom>
                            <StylishButton>{t("apply")}</StylishButton>
                          </AnchorLink>
                        </div>
                      </WorkCard>
                }
                </WorkCardGrid>
        </Wrapper>
      </StyledSection>
    </LayoutCV>
  );
};

export default Project;



export const workOfferQuery = graphql`
  query queryWorkOffer($id: String!) {
  directusOfertyPracy(id: { eq: $id }) {
      preview {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      name
      content
      content_en
      active
      skills {
        skill_name
      }
    }
  }
`;
